import React from "react";
import {isTouchDevice} from "../../util/util";
import {Link} from "react-router-dom";

export const PageGoBack = () => (
    <React.Fragment>
        {!isTouchDevice() ? (
            <nav className={"page__go-back"}>
                <Link to={"/"}>Zurück</Link>
            </nav>
        ) : ''}
    </React.Fragment>
)