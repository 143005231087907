import React from "react";
import Xing from "jsx:../../assets/icons/xing.svg";
import LinkedIn from "jsx:../../assets/icons/linkedin.svg";
import Github from "jsx:../../assets/icons/github.svg";
import "./socmed.scss";
import {Svg} from "../util/svg";
import {StopEvent} from "../../util/util";

export default function Socmed() {
    return (
        <div className={'socmed'}>
            <a className={'socmed__xing'} href="https://www.xing.com/profile/Leon_Dietsch" target="_blank" onTouchMove={StopEvent}>
                <Xing />
                <span className="visually-hidden">Zum Xing-Profil</span>
            </a>
            <a className={'socmed__linkedin'} href="https://www.linkedin.com/in/leon-dietsch/" target="_blank" onTouchMove={StopEvent}>
                <LinkedIn />
                <span className="visually-hidden">Zum Linked In-Profil</span>
            </a>
            <a className={'socmed__github'} href="https://github.com/leonlaser" target="_blank" onTouchMove={StopEvent}>
                <Github />
                <span className="visually-hidden">Zum GitHub-Profil</span>
            </a>
        </div>
    )
}