import React from "react";
import {Link} from "react-router-dom";
import "./meta-navigation.scss";
import {isTouchDevice} from "../../util/util";

export default function MetaNavigation(props) {
    return (
        <nav className="meta-navigation">
            <ul>
                {isTouchDevice() ? '' : <FollowMouse {...props}/>}

                <li>
                    <Link to="/datenschutz">Datenschutz</Link>
                </li>
                <li>
                    <Link to="/impressum">Impressum</Link>
                </li>
                {props.backLink && isTouchDevice() ? <Backlink/> : ''}
            </ul>
        </nav>
    );
};

const Backlink = () => (
    <li><Link to="/">zurück</Link></li>
);

const FollowMouse = (props) => (
    <li>
        <button onClick={() => props.setFollowMouse(!props.followMouse)}>
            Maus folgen: <strong>{props.followMouse ? 'An' : 'Aus'}</strong>
        </button>
    </li>
);
