import {matchesUA} from "browserslist-useragent";

// const isSafari = matchesUA(navigator.userAgent, {browsers: ['safari > 1'], ignoreMinor: true, ignorePatch: true, allowHigherVersions: true});
const isSafari = window.navigator.userAgent.match(/Safari/i) && !window.navigator.userAgent.match(/Chrome/i);

const isEdge = matchesUA(navigator.userAgent, {browsers: ['Edge > 1'], ignoreMinor: true, ignorePatch: true, allowHigherVersions: true});
const isIE = matchesUA(navigator.userAgent, {browsers: ['IE > 9'], ignoreMinor: true, ignorePatch: true, allowHigherVersions: true});

export const browserIs = {
    safari: isSafari,
    edge: isEdge,
    ie: isIE
};
