import React, {useEffect} from "react";
import {convertToEntities, setDocumentTitle} from "../../util/util";
import {PageGoBack} from "../util/pageGoBack";

export const Impress = () => {

    useEffect(() => {
        setDocumentTitle('Impressum');
    });

    return (
        <article className={"page"}>
            <PageGoBack/>
            <div className={"page__content"}>
                <h1>Impressum</h1>
                <p>
                    <strong>Leon Dietsch</strong>
                </p>
                <p>
                    Lohmühlenweg 15<br/>
                    63571 Gelnhausen
                </p>
                <p>
                    E-Mail: {convertToEntities('hallo@leondietsch.de')}<br/>
                    Telefon: +49 176 210 154 56
                </p>
                <p>
                    USt-IdNr. DE322964201
                </p>
            </div>
        </article>
    );
};