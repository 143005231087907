import React from "react";
import SvgSave from "jsx:../../assets/icons/save.svg";
import "./vcardDownload.scss";
import { Svg } from "../util/svg";
import vcard from "bundle-text:../../assets/leon_dietsch.vcf"
import { StopEvent } from "../../util/util";

export default function VcardDownload() {
    console.log(SvgSave);
    return (
        <div className={'vcard'}>
            <button className={'vcard__button'} onClick={downloadVcard.bind(this)} onTouchMove={StopEvent}>
                <SvgSave />
                <span className="visually-hidden">In Adressbuch speichern</span>
            </button>
        </div>
    )
}

function downloadVcard(event) {
    event.stopPropagation();
    event.preventDefault();
    download(vcard, 'Leon Dietsch.vcf')
}

function download(uri, name) {
    const blob = new Blob([vcard], { type: 'text/calendar' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = name;
    link.click()
}