export function classes(...args) {
    if (arguments.length > 0) {
        return args.join(' ');
    }

    return '';
}

export function returnAnimationNameFromEvent(event) {
    const animationName = event.animationName.split('--')[1];
    return animationName ? animationName : undefined;
}

let newIdCounter = 0;

export function newID(prefix = "id") {
    newIdCounter++;
    return `${prefix}${newIdCounter}`;
}

export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * max * 2) + min;
}


export function applyEasingTiming(transition, transform, refs) {
    for (let i = 0; i < refs.length; i++) {
        if (refs[i].current !== null) {
            transition && (refs[i].current.style.transition = transition);
            transform && (refs[i].current.style.transform = transform);
        }
    }
}

export function isTouchDevice() {
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    const mq = function (query) {
        return window.matchMedia(query).matches;
    };

    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
        return true;
    }

    const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
}

export const StopEvent = (event) => event.stopPropagation();

export function convertToEntities(rawStr) {
    return rawStr.replace(/[\u00A0-\u9999<>\&]/gim, function (i) {
        return '&#' + i.charCodeAt(0) + ';';
    });
}

export function setDocumentTitle(prefix) {
    const baseTitle = 'Leon Dietsch - Web-Entwickler für TYPO3 CMS und Angular';
    if(prefix === null) {
        document.title = baseTitle;
    } else {
        document.title = `${prefix} | ${baseTitle}`;
    }
}

export function watchMediaQuery(mediaQuery, callback) {
    if (window.hasOwnProperty('matchMedia')) {
        if (window.matchMedia.hasOwnProperty('addEventListener')) {
            window.matchMedia(mediaQuery).addEventListener('change', callback);
        } else {
            window.matchMedia(mediaQuery).addListener(callback);
        }
    }
}