import React, { useState } from "react";
import "./app.scss";
import { BusinessCard } from "./business-card/business-card";
import { browserIs } from "../util/browserIs";
import { classes, watchMediaQuery } from "../util/util";
import { Redirect, Route, Routes, useLocation } from "react-router-dom";
import MetaNavigation from "./meta-navigation/meta-navigation";
import { Impress } from "./impress/impress";
import { Privacy } from "./privacy/privacy";
import { CSSTransition, TransitionGroup } from "react-transition-group";

export const App = () => {
    const matchReduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)');
    const [followMouse, setFollowMouse] = useState(!matchReduceMotion.matches);
    const location = useLocation();

    watchMediaQuery('(prefers-reduced-motion: reduce)', (event) => setFollowMouse(!event.matches));

    const card = <BusinessCard followMouse={followMouse} />

    return (
        <main className={getClassNames(location)}>
            <TransitionGroup>
                <CSSTransition key={location.key} classNames={"page"} timeout={1000}>
                    <Routes location={location}>
                        <Route exact path="/impressum" element={<Impress />} />
                        <Route exact path="/datenschutz" element={<Privacy />} />
                        <Route exact path="/" element={card} />
                        <Route path="*" element={card} />
                    </Routes>
                </CSSTransition>
            </TransitionGroup>
            <MetaNavigation followMouse={followMouse} setFollowMouse={setFollowMouse} backLink={location.pathname !== '/'} />
        </main>
    )
};

const getClassNames = ({ pathname }) => {
    let base = ['app'];

    if (browserIs.safari) {
        base.push('safari-compat');
    } else if (browserIs.ie) {
        base.push('ie-compat');
    } else if (browserIs.edge) {
        base.push('edge-compat');
    } else {
        base.push('no-compat');
    }

    if (pathname === '/') {
        base.push('app--static-viewport')
    }

    return classes(...base);
}